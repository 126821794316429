//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'bucks-js'
import ClinicPhoto from './ClinicPhoto'
import VirtualClinicContent from './VirtualClinicContent'

export default {
    name: 'VirtualClinic',
    components: { ClinicPhoto, VirtualClinicContent },
    props: {
        clinic: { type: Object, required: true },
    },
    computed: {
        url () { return '/booking/' + this.clinic.id },
    },
    methods: {
        /**
         * Opens booking page on click.
         */
        openBooking () {
            if ($.ui.isClient()) {
                const query = $.urlQueryParam({
                    service: this.$route.params.service || this.$route.query.service,
                    ...this.$route.query
                })

                if (this.$route.query.inline) {
                    this.$store.commit('booking/setValue', { key: 'hideNavigationBar', value: true })
                    this.$store.commit('booking/setValue', { key: 'disableCanvasColumn', value: true })
                    this.$router.push({ path: this.url + query })
                } else {
                    window.open(this.url + query, '_blank')
                }
            }
        },
    },
}
