//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'bucks-js'

const widthValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const spacingValues = ['none', 'medium']

export default {
    name: 'SamedayItem',
    props: {
        // row count
        rowCount: { type: Number, default: 4 }, // how many items per row on desktop
        rowCountTablet: { type: Number, default: null }, // how many items per row on tablet
        rowCountMobile: { type: Number, default: null }, // how many items per row on mobile

        // width
        width: {
            type: Number,
            validator: (value) => { return $.array.includes(widthValues, value) },
            default: null,
        },
        tabletWidth: {
            type: Number,
            validator: (value) => { return $.array.includes(widthValues, value) },
            default: null,
        },
        mobileWidth: {
            type: Number,
            validator: (value) => { return $.array.includes(widthValues, value) },
            default: null,
        },

        // spacing
        spacing: {
            type: String,
            validator: (value) => { return $.array.includes(spacingValues, value) },
            default: 'medium',
        },
    },
    computed: {
        /**
         * Item count (css class for all device sizes).
         * @return {string}
         */
        classes () {
            let classes = []

            // spacing
            if (this.spacing !== 'none') {
                classes.push('sameday-item-spacing-' + this.spacing)
            }

            // width and row count -> desktop
            if (this.width) {
                classes.push('sameday-item-count-' + this.convertWidthToRowCount(this.width))
            } else {
                classes.push('sameday-item-count-' + this.rowCount)
            }

            // width and row count -> tablet
            if (this.tabletWidth) {
                classes.push('sameday-item-count-tablet-' + this.convertWidthToRowCount(this.tabletWidth))
            } else if (this.rowCountTablet) {
                classes.push('sameday-item-count-tablet-' + this.rowCountTablet)
            }

            // width and row count -> mobile
            if (this.mobileWidth) {
                classes.push('sameday-item-count-mobile-' + this.convertWidthToRowCount(this.mobileWidth))
            } else if (this.rowCountMobile) {
                classes.push('sameday-item-count-mobile-' + this.rowCountMobile)
            }

            return classes.join(' ')
        },
    },
    methods: {
        /**
         * Convert width (percentage) to row count.
         * 100 / width -> Round two digits and replace comma with underscore.
         * @param width
         */
        convertWidthToRowCount (width) {
            const quotient = Math.round(((100 / width) + Number.EPSILON) * 100) / 100
            return quotient.toString().replace('.', '_')
        },
    },
}
