//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SamedayLayout from '../UserInterface/layout/SamedayLayout'
import SamedayItem from '../UserInterface/layout/SamedayItem'
import Clinic from '../ClinicCard/Clinic'
import VirtualClinic from '../ClinicCard/components/VirtualClinic'

export default {
    name: 'ClinicGroupPanel',
    components: { VirtualClinic, SamedayLayout, SamedayItem, Clinic },
    props: {
        group: { type: Object, required: true },
        clinicRowCount: { default: 3 },
        clinicTabletRowCount: { default: 2 },
        clinicMobileRowCount: { default: 1 },
    },
    computed: {
        virtualPanel () {
            return ['Sameday House Calls', 'Virtual Care', 'Virtual Therapy'].indexOf(this.group.name) > -1
        }
    }
}
